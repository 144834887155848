<template>
  <ServiceLayout>
    <div class="md-service-1">
      <!-- 广告图 -->
      <Banner :list="bannerList" height="4.92rem" v-ani.fade-up />
      <!-- 流程 -->
      <ServiceProcess :info="info" v-ani.fade-up />
      <!-- 提供服务 -->
      <ServiceList title='提供服务' :list='list' />
    </div>
  </ServiceLayout>
</template>

<script>
import { IndexBannerApi } from "@/request/api/user";
export default {
  created() {
    // 轮播图
    IndexBannerApi({ from: 425 }).then(({ status, data }) => {
      if (status == 200) {
        this.bannerList = data;
      }
    })
  },
  data() {
    return {
      // 轮播图列表
      bannerList: [
        // {
        //   id: 1,
        //   link: '',
        //   btn: false,
        //   target: '',
        //   img: require('@/assets/images/svc9_0.jpg'),
        //   title: '工程法务',
        //   intro: '专业团队，法律顾问'
        // },
      ],
      info: {
        title: '工程法务',
        desc: ' 在保护工程项目的合法权益，确保项目顺利进行并遵守相关法律法规。具备专业的法律知识和实践经验，熟悉工程建设、投资、融资等领域的法律法规和政策规定，同时需要具备良好的沟通协调能力和团队合作精神。在工程项目中，工程法务人员需要参与合同管理、法律咨询、知识产权保护、商业秘密维护等方面的工作，为企业的合法合规经营提供保障和支持。',
        list: [
          { id: 1, title: '接受委托' },
          { id: 2, title: '合同审查' },
          { id: 3, title: '风险评估' },
          { id: 4, title: '法律咨询' },
          { id: 5, title: '诉讼或仲裁' },
          { id: 6, title: '执行' },
        ]
      },
      list: [
        {
          id: 1,
          img: require('@/assets/images/svc9_1.jpg'),
          tit: '合同管理',
          desc: '工程法务人员负责审核、修改、谈判工程类合同，如EPC合同、联合体协议等，确保合同的合规性、有效性，并针对合同执行过程中的问题提供法律意见。'
        }, {
          id: 2,
          img: require('@/assets/images/svc9_2.jpg'),
          tit: '法律咨询',
          desc: '为工程项目提供法律咨询，包括但不限于工程索赔、仲裁、诉讼等方面的法律问题，帮助企业合理规避风险。'
        }, {
          id: 3,
          img: require('@/assets/images/svc9_3.jpg'),
          tit: '纠纷处理',
          desc: '参与工程项目纠纷的处理和解决，代表企业进行法律诉讼和仲裁，协助企业维护自身利益。'
        }, {
          id: 4,
          img: require('@/assets/images/svc9_4.jpg'),
          tit: '知识产权保护',
          desc: '工程法务人员负责协助企业进行知识产权保护和商业秘密维护等工作，确保企业的合法权益得到保障。'
        },
      ]
    };
  },
};
</script>

<style lang="scss" scoped></style>